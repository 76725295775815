<template>
  <div>
    <h3 class="mb-4" v-html="formTitle" />
    <p class="mb-5" v-html="formSubTitle" />
    <validation-observer v-slot="{ invalid }" v-if="api_response == ''">
      <b-form class="border border-light rounded p-3">
        <b-row>
          <b-col xl="8">
            <b-row>
              <b-col md="4">
                <validation-provider rules="required|alpha_spaces|min:2" name="Nome" v-slot="{ errors }">
                  <div class="vb-form">
                    <b-form-group label-for="input" label="Nome*" label-class="vb-label">
                      <vb-input v-model="firstname" :vb_options="{ autocomplete: 'chrome-off'}" />
                    </b-form-group>
                    <span class="vee--errors">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider rules="required|alpha_spaces|min:2" name="Cognome" v-slot="{ errors }">
                  <div class="vb-form">
                    <b-form-group label-for="input" label="Cognome*" label-class="vb-label">
                      <vb-input v-model="lastname" :vb_options="{ autocomplete: 'chrome-off'}" />
                    </b-form-group>
                    <span class="vee--errors">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider rules="alpha_spaces|min:2" name="Azienda" v-slot="{ errors }">
                  <div class="vb-form">
                    <b-form-group label-for="input" label="Azienda" label-class="vb-label">
                      <vb-input v-model="company" :vb_options="{ autocomplete: 'chrome-off'}" />
                      <span class="vee--errors">{{ errors[0] }}</span>
                    </b-form-group>
                  </div>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider rules="required|email" name="E-mail" ref="E-mail" v-slot="{ errors }">
                  <div class="vb-form">
                    <b-form-group label-for="input" label="E-mail*" label-class="vb-label">
                      <vb-input v-model="email" :vb_options="{ autocomplete: 'chrome-off'}" />
                    </b-form-group>
                    <span class="vee--errors">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider rules="required|confirmed:E-mail" name="Conferma E-mail" v-slot="{ errors }">
                  <div class="vb-form">
                    <b-form-group label-for="input" label="Conferma E-mail*" label-class="vb-label">
                      <vb-input v-model="email_confirm" :vb_options="{ autocomplete: 'chrome-off'}" />
                    </b-form-group>
                    <span class="vee--errors">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col xl="4">
            <b-row>
              <b-col>
                <validation-provider rules="required|min:20" name="Messaggio" v-slot="{ errors }">
                  <div class="vb-form">
                      <b-form-group label-for="input" label="Messaggio*" label-class="vb-label">
                      <b-form-textarea v-model="message" no-resize rows="5" />
                    </b-form-group>
                    <span class="vee--errors">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6" offset-lg="6" xl="4" offset-xl="8">
            <div class="d-flex align-items-center justify-content-between">
              <vb-checkbox v-model="privacy_policy" :vb_options="{ text: 'Ho letto e accetto la <a target=\'_blank\' href=\' ' + config.site.urlPrivacy + '\'>privacy policy</a>.' }" />
              <vb-button
                  v-if="recaptcha_enabled"
                  :vb_options="{
                  disabled: invalid || conditions,
                  size: 'lg',
                  text: 'Invia'
                }"
                  @click="recaptcha"
              />
              <vb-button
                  v-else
                  :vb_options="{
                  disabled: invalid || conditions,
                  size: 'lg',
                  text: 'Invia'
                }"
                  @click="request_access"
              />
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row v-else>
      <b-col lg="12">
        <b-alert variant="info" class="text-center" show>
          <strong>{{api_response}}</strong>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { service_handler } from '@/services'

export default {
  name: 'form-contatti',
  components: {

  },
  props: {
    formTitle: {
      type: String,
      default: '', // titolo del form, renderizzato in alto se != ''
    },
    formSubTitle: {
      type: String,
      default: '', // sottotitolo del form, renderizzato in alto se != ''
    },
    type: {
      type: String,
      default: 'form-contatti', // tipo form, usato per distinguere i form e per tracking
    },
    label: {
      type: String,
      default: '', // per distinguere lo stesso form messo in posti diversi, usato anche per tracking
    },
    recipient: {
      type: String,
      default: 'assistenza@vadobay.com', // email destinatario
    }
  },

  data() {
    return {
      config: Vue.prototype.$config,
      firstname: '',
      lastname: '',
      company: '',
      email: '',
      email_confirm: '',
      message: '',
      privacy_policy: false,
      loading: false,
      recaptcha_enabled: Vue.prototype.$config.recaptcha.enabled,
      recaptcha_token: '',
      api_response: '',
    }
  },

  created() {
  },

  computed: {
    conditions() {
      return !this.privacy_policy
    },
  },

  mounted() {
    if (Vue.prototype.$config.recaptcha.enabled) {
      Vue.use(VueReCaptcha, {
        siteKey: Vue.prototype.$config.recaptcha.siteKey,
      })
    }
  },

  methods: {

    async recaptcha() {
      this.loading = true
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('form_submitter')
      this.recaptcha_token = token
      this.request_access()
    },

    request_access() {
      this.loading =  true

      let parameters = {
        recaptcha: this.recaptcha_token,
        fields: {
          firstName: this.firstname,
          lastName: this.lastname,
          company: this.company,
          email: this.email,
          message: this.message,
        },
        settings: {
          type: this.type,
          label: this.label,
          recipient: this.recipient,
          formTitle: this.formTitle,
          formSubTitle: this.formSubTitle,
        }
      }
      service_handler(
          'formsSubmitter',
          parameters,
      )
      .then(
        (success) => {
            this.api_response = success.data.msg
            this.loading = false
        },
        (error) => {
            this.log('[scheda.vue] - error')
            this.log(error.response)
            this.api_response = error.response.data
            this.loading = false
        }
      )
    }
  }

}
</script>

<style lang="scss" scoped>

  .vb-check {
    font-size: $font-size-md;
  }

</style>